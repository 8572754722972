import { ignoreConsoleError, isClient, logVersion } from '@zep/utils';

import { initPrototypes } from './utils';

if (isClient()) {
  // React의 문제로 불필요하게 Noisy한 Error가 발생하는데, 18.2.0이하로 downgrade하거나 19로 upgrade해야 고쳐집니다.
  // 개발 환경에서 직접 해당 warning을 무시하는 방식으로 해결합니다.
  // Ref: https://github.com/facebook/react/issues/28948
  ignoreConsoleError('React does not recognize the `fetchPriority` prop');

  logVersion();
}

initPrototypes();
