import {
  EduMapAsset,
  StoreMapAsset,
  StoreMapAssetQueryOptions,
} from '@zep/types';

import {
  useOffsetInfiniteQuery,
  UseOffsetInfiniteQueryOptions,
} from '../../hooks';
import {
  OffsetPaginationOptions,
  ZepApiOffsetPaginationResponse,
} from '../types';
import { useZepApiClient } from '../ZepApiProvider';

export const QUERY_KEY_STORE = 'store';

/**
 * 구입한 맵 목록 조회 API Hook
 * @version V2
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function usePurchasedMaps(
  mapAssetQueryOptions: StoreMapAssetQueryOptions,
  paginationOptions: OffsetPaginationOptions,
  options?: UseOffsetInfiniteQueryOptions<
    ZepApiOffsetPaginationResponse<'items', StoreMapAsset[]>
  >,
) {
  const client = useZepApiClient();
  return useOffsetInfiniteQuery(
    [QUERY_KEY_STORE, 'purchase-map-assets'],
    async ({ pageParam }) => {
      const { page, limit } = pageParam || paginationOptions;
      const { data } = await client.get('/v2/store/purchase-map-assets', {
        params: { ...mapAssetQueryOptions, page, limit },
      });
      return data;
    },
    options,
  );
}

/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function usePurchasedEduMaps(
  mapAssetQueryOptions: StoreMapAssetQueryOptions,
  paginationOptions: OffsetPaginationOptions,
  options?: UseOffsetInfiniteQueryOptions<
    ZepApiOffsetPaginationResponse<'items', EduMapAsset[]>
  >,
) {
  const client = useZepApiClient();
  return useOffsetInfiniteQuery(
    [QUERY_KEY_STORE, 'purchase-edu-map-assets'],
    async ({ pageParam }) => {
      const { page, limit } = pageParam || paginationOptions;
      const { data } = await client.get('/v2/edu/store/purchase-map-assets', {
        params: { ...mapAssetQueryOptions, page, limit },
      });
      return data;
    },
    options,
  );
}
