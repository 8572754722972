import { ReactNode } from 'react';

import { cn } from '@zep/utils';

interface Props extends React.VideoHTMLAttributes<HTMLVideoElement> {
  containerClassName?: string;
  posterComponent?: ReactNode;
}

/**
 * Viewport에 따라 높이가 자동으로 조정되는 컨테이너를 가진 비디오 컴포넌트
 * containerClassName, posterComponent 이용해서 fallback용 컴포넌트를 넣을 수 있습니다.
 */
export function ResponsiveVideo({
  containerClassName,
  posterComponent,
  ...videoProps
}: Props) {
  return (
    <div className={cn('relative aspect-video', containerClassName)}>
      {posterComponent}
      <video
        {...videoProps}
        className={cn(
          'absolute left-0 top-0 size-full object-cover',
          videoProps.className,
        )}
      />
    </div>
  );
}
