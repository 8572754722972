import { CountryCode } from '../common';
import { SpaceRole } from '../space';
import { PlanType } from '../subscription';

import { SpaceMapAccessInfoDto, SpaceMapSimpleDto, TokenInfo } from './map';

/**
 * 스페이스
 * @version V1
 */
export interface SpaceV1Response {
  hash_id: string;

  owner_player_hash_id: string;

  name: string;

  desc: string;

  thumbnail_url: string;
}

export type UserRequest = 'NONE' | 'UNSUBSCRIBE' | 'DOWNGRADE';

export interface SpaceDto {
  hashId: string;
  name: string;
  description: string;
  onlinePlayers: number;
  channelMaxPlayers: number;
  thumbnailUrl: string; // 설정창 보여주기용 url
  thumbnailPath: string; // 실제 서버로 보내야되는 path data
  coverUrl: string; // 설정창 보여주기용 url
  coverPath: string; // 실제 서버로 보내야되는 path data
  whitelistCount: number;
  ownerPlayerHashId: string;
  channelingType: number;
  updatedAt: string;
  createdAt: string;
  notice: string;
  noticeUpdatedAt: string;
  entryMapHashId: string;
  blockedKeywords: string[] | null;
  blockedNicknames: string[] | null;
  options: Partial<SpaceSettingOptions>;
  tokenInfo: TokenInfo;
  categoryIds: number[];
  allowSearch: boolean;
  countryCode: CountryCode;
  isEdu: boolean;
  enableGa: boolean;
  enableBlockedKeywords: boolean;
}

export enum SpaceUsageType {
  LEGACY,
  PUBLIC,
  PRIVATE,
}

interface SpaceWithMapsDto extends SpaceDto {
  mapHashIds: string[];
}

export type Space = SpaceWithMapsDto;

export interface SpaceSimpleDto {
  hashId: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  onlinePlayers: number;
  entryMapHashId: string;
  ownerPlayerHashId: string;
  countryCode: CountryCode;
  updatedAt: string;
  createdAt: string;
  myRole: number;
  isCopyable: boolean;
  isEdu: boolean;
  spacePlanType: PlanType;
}

export interface SpaceAccessInfoDto {
  hashId: string;
  name: string;
  description: string;
  ownerPlayerName: string;
  spaceOption: SpaceOptions;
  entryMapHashId: string;
  thumbnailUrl: string;
  channelingType: SpaceChannelingType;
  categoryIds: number[];
  updatedAt: string;
  createdAt: string;
  countryCode: CountryCode;
  allowSearch: boolean;
}

export type SpacesQueryOptions =
  | {
      type: SpacesQuery.RECENT | SpacesQuery.MY;
      spaceHashIds?: never;
      spaceName?: string;
    }
  | {
      type: SpacesQuery.TARGETS;
      spaceHashIds?: string[];
      spaceName?: never;
    };

export enum SpacesQuery {
  // BOOKMARK = 'bookmark',
  RECENT = 'recently-visited',
  MY = 'related-me',
  TARGETS = 'targets',
}

export type SpaceDonation = {
  type: string;
  amount: number;
  spaceName: string;
  donateType: string;
  updatedAt: string;
  createdAt: string;
  senderHashID: string;
  targetHashID: string;
  senderName: string;
  targetName: string;
  rank: number;
};

/**
 * 스페이스 후원 api interface
 * */
export interface SpaceDonationsResponse {
  status: number;
  donations: SpaceDonation[];
}

export interface SpaceSettingsRequestModel {
  spaceHashId: string;
  name: string;
  description?: string;
  thumbnailPath?: string;
  coverPath?: string;
  entryMapHashId: string;
  blockedKeywords: string;
  allowSearch: boolean;
  spaceOptions: SpaceSettingOptions;
  spacePremiumOptions?: {
    logoPath: string;
    loadingSpinnerPath: string;
    loadingColor: string;
    customDomain: string;
    gaId: string;
  };
  enableBlockedKeywords?: boolean;
  enableGa?: boolean;
}

export enum SpaceChannelingType {
  NONE = 0, // 미설정
  SINGLE = 1, // 노채널
  ASC = 2, // 낮은 번호순
  RANDOM = 3, // 랜덤
  NEW = 4, // 항상 생성
}

export interface SpacePremiumOptionsDto {
  customDomain: string;
  customDomainSetAt: string;
  gaId: string;
  logoUri: string;
  logoPath: string;
  loadingColor: string;
  loadingSpinnerUri: string;
  loadingSpinnerPath: string;
  expiredAt: string;
  updatedAt: string;
  createdAt: string;
}
export type SpacePremiumOptions = SpacePremiumOptionsDto;

export interface SpaceMapAccessInfoRequest {
  mapHashId?: string;
  spaceHashId?: string;
  /** 프리미엄 스페이스 도메인 그대로 전달*/
  customDomain?: string;
  'X-Token'?: string;
}

export interface SpaceMapAccessInfoResponse {
  status: number;
  space: SpaceAccessInfoDto;
  map: SpaceMapAccessInfoDto;
  spacePremiumOptions: SpacePremiumOptionsDto | null;
  spacePlan: SpacePlanResponse | null;
  spacePlanSubscription: AccessInfoSubscription | null;
  links: SidebarLink[];
  portals: SidebarPortal[];
  exceedSidebarAppsByPlan: boolean;
  isDowngradedPlan: boolean;
  isGrandfatheredPlan: boolean;
}

export type AccessInfoSubscription = {
  endDate: string;
  nextCreationDate: string;
  operationType: OperationType;
  startDate: string;
  subscriptionHashId: string;
  requestedAction: UserRequest;
};

export interface SpacePlanResponse {
  countryCode: CountryCode;
  createdAt: string;
  expectedEndDate: string;
  isCancel: boolean;
  isCustom: boolean;
  optionsString: string;
  planStatus: string;
  spaceName: string;
  spacePlanTemplateId: number;
  updatedAt: string;
  additionalInformation: boolean;
  currency: string;
  endDate: string;
  maxCcu: number;
  name: string;
  operationType: OperationType;
  originalPrice: number;
  paymentExpectedDate: null;
  paymentFailCount: number;
  planType: PlanType;
  options: SpacePlanOptions;
  salePrice: number;
  saleType: string;
  spaceHashId: string;
  spacePlanHashId: string;
  startDate: string;
  status: PlanStatus;
  verificationCode: string;
}

export enum OperationType {
  일시납 = 'ONETIME_TEMPLATE',
  커스텀_일시납 = 'ONETIME_CUSTOM',
  정기 = 'SUBSCRIPTION_TEMPLATE',
  커스텀_정기 = 'SUBSCRIPTION_CUSTOM',
}

export enum PlanStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  READY = 'READY',
}

export enum LabelStatus {
  FAIL = 'fail',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  READY = 'ready',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  NONE = 'none',
}

export interface SpacePlanOptions {
  customUrl: boolean;
  customLoading: boolean;
  googleAnalytics: boolean;
  dataDashboard: boolean;
  additionalInformation: boolean;
  adPopupBlock: boolean;
  premiumApp: boolean;
  premiumFunction: boolean;
  premiumPlanServer: boolean;
  privateServer: boolean;
  ssoEnabled: boolean;
  topPriorityCs: boolean;
}

export type SidebarItem = {
  type: 'link' | 'portal';
  hashId?: string;
  emoji: string;
  name: string;
  sortOrder: number;
  targetMapX: number;
  targetMapY: number;
  targetMapHashId: string;
  url: string;
};

export type SidebarPortal = Pick<
  SidebarItem,
  | 'targetMapHashId'
  | 'targetMapX'
  | 'targetMapY'
  | 'emoji'
  | 'name'
  | 'hashId'
  | 'sortOrder'
>;
export type SidebarLink = Pick<
  SidebarItem,
  'url' | 'emoji' | 'name' | 'hashId' | 'sortOrder'
>;

interface SidebarResponse<T> {
  status: number;
  items: T;
}

export type SidebarItemsResponse = SidebarResponse<SidebarItem[]>;
export type SidebarPortalsResponse = SidebarResponse<SidebarPortal[]>;
export type SidebarLinksResponse = SidebarResponse<SidebarLink[]>;

export type SpaceChannel = {
  channelId: number;
  channelNumber: number;
  host: string;
  isFull: boolean;
  maxPlayers: number;
  onlinePlayers: number;
  spaceHashId: string;
};

export interface SpaceChannelsResponse {
  status: number;
  spaceChannels: SpaceChannel[];
}

export interface SpaceMember {
  spacePlayerHashId: string;
  playerHashId: string;
  playerEmail: string;
  role: SpaceRole;
  updatedAt: string;
  createdAt: string;
  visitedAt: string;
  playerName: string;
  statusText: '' | 'Sent invitation';
}

export interface SpaceMembersResponse {
  status: number;
  members: SpaceMember[];
}

export interface SpaceBlackListResponse {
  status: number;
  blacklist: SpaceBlackListItem[];
}

export interface SpaceBlackListItem {
  spaceBlacklistHashId: string;
  name: string;
  value: string;
  createdAt: string;
  updatedAt: string;
}

export interface SpaceCategory {
  id: number;
  category: string;
  visible: boolean;
  priority: number;
  prefixIcon: string | null;
}

export interface SpaceOptions {
  enablePassword: boolean;
  entryMapHashId?: string;
  allowSearch?: boolean;
  enableFilterBadWords: boolean;
  blockedKeywords?: string[];
  hideInvitation: boolean;
  hideSidebarOnStart: boolean;
  forceStartAtEntryMap: boolean;
  enableInitialNicknameSetting: boolean;
  enableWhitelisting: boolean;
  enableDomainRestriction: boolean;
  disableGuestLogin: boolean;
  enableDonation: boolean;
  donationFeeBp: number;
  activateSsoLogin?: boolean;
  countAllOnlinePlayers?: boolean;
  isSignatureSpace?: boolean;
  enableCryptoWallet: boolean;
  enableAdditionalInformation: boolean;
  additionalInformationQuestion: string;
  enableTutorial: boolean;
  rtcHostUrl: string;
  disableFlyMode: boolean;
  enableLogflare: boolean;
  spaceUsageType?: SpaceUsageType;
  //
}

export type SpaceSettingOptions = SpaceOptions & {
  password: string;
  allowedDomains: string;
};

export interface SpaceSettingsResponse {
  status: number;
  space: SpaceDto;
  spacePremiumOptions: SpacePremiumOptionsDto;
  maps: SpaceMapSimpleDto[];
}
export interface SpaceMemberListType {
  status: number;
  membersCount: number;
}

export enum TempImageUploadType {
  NONE = 0,
  ICON = 1,
  THUMBNAIL = 2,
  PREVIEW = 3,
  OBJECT_IMAGES = 4,
  COVER = 5,
  PREMIUM_SPACE_LOGO = 201,
  PREMIUM_SPACE_LOADING_SPINNER = 202,
}

export interface SpaceRealTimeData {
  spaceHashId: string;
  enableDonation?: boolean;
  donationFeeBp?: number;
  enableCryptoWallet?: boolean;
  enableAdditionalInformation?: boolean;
  additionalInformationQuestion?: string;
  enableInitialNicknameSetting?: boolean;
  spacePremiumOptions?: Partial<
    Pick<
      SpacePremiumOptions,
      | 'customDomain'
      | 'logoPath'
      | 'loadingColor'
      | 'loadingSpinnerPath'
      | 'gaId'
    >
  >;
}

export type SpaceImagePathType = 'thumbnailPath' | 'coverPath';

export type PremiumSpaceImageType = 'logoPath' | 'loadingSpinnerPath';

export interface OwnedSpacesResponse {
  spaces: OwnedSpace[];
  status: number;
}

export interface OwnedSpace {
  hashId: string;
  name: string;
  description: any;
  thumbnailUrl: string;
  onlinePlayers: number;
  entryMapHashId: string;
  ownerPlayerHashId: string;
  countryCode: CountryCode;
  updatedAt: string;
  createdAt: string;
  myRole: number;
  isCopyable: boolean;
  spacePlanType: string;
}
