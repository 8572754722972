/**
 * Ref: https://developer.mozilla.org/en-US/docs/Web/API/console#using_string_substitutions
 * console.log에서는 text substitution을 사용할 수 있습니다.
 * log를 무시하기 위해선 message를 capture해야 하는데요.
 * substitution을 사용한 message를 capture하기 위한 helper function입니다.
 * e.g. substitutionAppliedMessage('hello %s', 'world') -> hello world
 */
const applySubstitution = (message: string, ...args: unknown[]) => {
  return args.reduce<string>((prev, current) => {
    return prev.replace(/%s|%d|%i|%f|%o|%O/, String(current));
  }, message);
};

export function ignoreConsoleError(ignoreString: string) {
  if (process.env.NEXT_PUBLIC_STAGE === 'LOC') {
    const originalError = console.error;
    console.error = (message, ...args) => {
      const appliedMessage = applySubstitution(message, ...args);

      if (appliedMessage.includes(ignoreString)) {
        return;
      }
      originalError(message, ...args);
    };
  }
}
