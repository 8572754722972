import { QueryKey, useQuery } from '@tanstack/react-query';

import {
  MapAccessRequest,
  MapAccessResponse,
  MapDetailResponse,
  MapObjectsRequest,
  MapObjectsResponse,
  MapsResponse,
  MapTemplate,
  MapTemplateQueryOptions,
} from '@zep/types';

import {
  useOffsetInfiniteQuery,
  UseOffsetInfiniteQueryOptions,
} from '../../hooks';
import {
  OffsetPaginationOptions,
  UseZepApiQueryOptions,
  ZepApiOffsetPaginationResponse,
} from '../types';
import { useZepApiClient } from '../ZepApiProvider';

export const QUERY_KEY_ACCESS_MAP = 'accessMap';

export const QUERY_KEY_MAP_LIST = 'mapList';
export const QUERY_KEY_MAP_DETAIL = 'mapDetail';

export const QUERY_KEY_MAP_CHAT_HISTORY = 'mapChatHistory';
export const QUERY_KEY_MAP_TEMPLATES = 'mapTemplates';

/**
 * 맵 접속 요청
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export async function postMapAccess(
  client: ReturnType<typeof useZepApiClient>,
  params: MapAccessRequest,
) {
  const { data } = await client.post<MapAccessResponse>(
    `/v2/maps/access`,
    params,
  );
  return data;
}

/**
 * 맵에 존재하는 오브젝트 목록
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export async function fetchMapObjects(
  client: ReturnType<typeof useZepApiClient>,
  params: MapObjectsRequest,
) {
  const { data } = await client.get<MapObjectsResponse>(`/v2/maps/objects`, {
    params,
  });
  return data;
}

/**
 * * @desc 스페이스에 속해 있는 맵 리스트 호출
 * * @see {@link https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_GetSpaceMaps} *
 * * @alias API_V2_SPACES_MAPS
 * * @returns Query States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useMapsInSpace(
  spaceHashId: string,
  options?: UseZepApiQueryOptions<MapsResponse>,
) {
  const client = useZepApiClient();

  return useQuery(
    [QUERY_KEY_MAP_LIST, spaceHashId] as QueryKey,
    async () => {
      try {
        const { data } = await client.get<MapsResponse>('/v2/spaces/maps', {
          params: {
            spaceHashId,
          },
        });
        return { status: 0, maps: data.maps.reverse() };
      } catch (e) {
        // 서버 에러 대비 처리
        console.log(e);
        return { status: -1, maps: [] };
      }
    },
    {
      ...options,
    },
  );
}

/**
 * * @desc 맵의 상세 정보 불러오기
 * * @see {@link https://localhost/swagger/index.html#/ApiV2Map/ApiV2Map_GetSpaceMapDetails} *
 * * @alias API_V2_MAPS_DETAILS
 * * @returns Query States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useMapDetail(
  mapHashId: string,
  options?: UseZepApiQueryOptions<MapDetailResponse>,
) {
  const client = useZepApiClient();

  return useQuery(
    [QUERY_KEY_MAP_DETAIL, mapHashId] as QueryKey,
    async () => {
      const { data } = await client.get<MapDetailResponse>(
        '/v2/maps/settings',
        {
          params: {
            mapHashId,
          },
        },
      );
      return data;
    },
    {
      suspense: false,
      useErrorBoundary: false,
      ...options,
    },
  );
}

/**
 * 맵 템플릿 목록 조회 API Hook
 * @version V2
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useMapTemplates(
  mapTemplateQueryOptions: MapTemplateQueryOptions,
  paginationOptions: OffsetPaginationOptions = {},
  options?: UseOffsetInfiniteQueryOptions<
    ZepApiOffsetPaginationResponse<'results', MapTemplate[]>
  >,
) {
  const client = useZepApiClient();
  return useOffsetInfiniteQuery(
    [QUERY_KEY_MAP_TEMPLATES, mapTemplateQueryOptions],
    async ({ pageParam }) => {
      const { page, limit } = pageParam || paginationOptions;
      const { data } = await client.get('/v2/maps/templates', {
        params: { ...mapTemplateQueryOptions, page, limit },
      });
      return data;
    },
    options,
  );
}
