import {
  InfiniteData,
  QueryKey,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import {
  OwnedSpacesResponse,
  SpaceBlackListResponse,
  SpaceCategory,
  SpaceChannelsResponse,
  SpaceDonationsResponse,
  SpaceDto,
  SpaceMapAccessInfoRequest,
  SpaceMapAccessInfoResponse,
  SpaceMembersResponse,
  SpaceSettingsResponse,
  SpaceSimpleDto,
  SpacesQuery,
  SpacesQueryOptions,
} from '@zep/types';

import {
  SuspendedUseQueryOptions,
  useCursorInfiniteQuery,
  UseCursorInfiniteQueryOptions,
  useSuspendedQuery,
} from '../../hooks';
import {
  CursorPaginationOptions,
  UseZepApiQueryOptions,
  ZepApiCursorPaginationResponse,
} from '../types';
import { useZepApiClient } from '../ZepApiProvider';

import { SuspenseQueryOption } from './../../hooks/useSuspendedQuery';
import { SearchedSpacesResponse } from './types';

export const QUERY_KEY_SPACES = 'spaces';
export const QUERY_KEY_SPACE_DETAIL = 'spaceDetail';
export const QUERY_KEY_SPACE_SETTINGS = 'spaceSettings';
export const QUERY_KEY_SPACE_DONATIONS = 'spaceDonations';
export const QUERY_KEY_SPACE_CHANNELS = 'spaceChannels';
export const QUERY_KEY_SPACE_SIDEBAR_PORTALS = 'spaceSidebarPortals';
export const QUERY_KEY_SPACE_SIDEBAR_LINKS = 'spaceSidebarLinks';
export const QUERY_KEY_SPACE_MEMBERS = 'spaceMembers';
export const QUERY_KEY_SPACE_BLACKLIST = 'spaceBlacklist';
export const QUERY_KEY_SPACE_MAX_BLACKLIST = 'spaceMaxBlacklist';
export const QUERY_KEY_SPACE_CATEGORY = 'spaceCategory';
export const QUERY_KEY_SPACE_PREMIUM_OPTION_INSIGHT =
  'spacePremiumOptionInsight';

export const QUERY_KEY_SPACE_MAP_ACCESS_INFO = 'spaceMapAccessInfo';

export const getDonationUrl = '/v2/spaces/donations/ranking';

/**
 * @desc 해당 스페이스 후원 랭킹 찾는 Hook
 * @version V2
 * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_DonationsRankings}
 * @return Query States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useDonationsRankings(
  spaceHashId: string,
  limit?: number,
  options?: SuspenseQueryOption<SpaceDonationsResponse>,
) {
  const client = useZepApiClient();
  return useSuspendedQuery(
    [QUERY_KEY_SPACE_DONATIONS, spaceHashId],
    async () => {
      const { data } = await client.get<SpaceDonationsResponse>(
        getDonationUrl,
        {
          params: {
            spaceHashId,
            limit,
          },
        },
      );
      return data;
    },
    options,
  );
}

/**
 * @desc 해당 스페이스 채널들
 * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_Channels}
 * @return Query States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceChannels(
  { spaceHashId, playerSnsId }: { spaceHashId: string; playerSnsId: string },
  options?: UseZepApiQueryOptions<SpaceChannelsResponse>,
) {
  const client = useZepApiClient();
  return useQuery(
    [QUERY_KEY_SPACE_CHANNELS, spaceHashId] as QueryKey,
    async () => {
      const { data } = await client.post<SpaceChannelsResponse>(
        `/v2/spaces/channels`,
        {
          playerSnsId,
          spaceHashId,
        },
      );

      return data;
    },
    options,
  );
}

/**
 * 스페이스 목록 조회 API Hook
 * @version V2
 * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_SearchSpacesRecentlyVisited}
 * @alias API_V2_SPACES_TARGETS | API_V2_SPACES_SEARCH_RELATED_ME | API_V2_SPACES_SEARCH_RECENTLY_VISITED
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaces(
  spacesQueryOptions: SpacesQueryOptions,
  paginationOptions: CursorPaginationOptions,
  options?: UseCursorInfiniteQueryOptions<
    ZepApiCursorPaginationResponse<'spaces', SpaceSimpleDto[]>
  >,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();

  const { type, spaceName, spaceHashIds } = spacesQueryOptions;

  return useCursorInfiniteQuery(
    [QUERY_KEY_SPACES, spacesQueryOptions, paginationOptions],
    async ({ pageParam }) => {
      const { next, limit } = pageParam || paginationOptions;
      const { data } = await client.request(
        type === SpacesQuery.TARGETS
          ? {
              method: 'post',
              url: '/v2/spaces/targets',
              data: { spaceHashIds },
            }
          : {
              method: 'get',
              url: `/v2/spaces/${(spaceName ? 'search/' : '') + type}`,
              params: { next, limit, spaceName },
            },
      );
      return data;
    },
    {
      ...options,
      placeholderData: () => {
        return queryClient.getQueryData(
          [QUERY_KEY_SPACES, spacesQueryOptions],
          {
            exact: type === SpacesQuery.TARGETS,
          },
        ) as InfiniteData<any>;
      },
      enabled: type === SpacesQuery.TARGETS ? !!spaceHashIds : true,
    },
  );
}

/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpacePremiumOptionInsight(
  spaceHashId: string,
  options?: UseZepApiQueryOptions<{
    status: number;
    isGrandfatheredPlan: boolean;
    isDowngradedPlan: boolean;
    hasUsedDashboard: boolean;
  }>,
) {
  const client = useZepApiClient();
  return useQuery(
    [QUERY_KEY_SPACE_PREMIUM_OPTION_INSIGHT, spaceHashId] as QueryKey,
    async () => {
      const { data } = await client.get(`/v2/spaces/premium-option-insight`, {
        params: {
          spaceHashId,
        },
      });
      return data;
    },
    options,
  );
}

/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useSpaceDetail(
  spaceHashId: string,
  options?: UseZepApiQueryOptions<SpaceDto>,
) {
  const client = useZepApiClient();
  return useQuery(
    [QUERY_KEY_SPACE_DETAIL, spaceHashId] as QueryKey,
    async () => {
      const { data } = await client.get(`/v2/spaces`, {
        params: {
          spaceHashId,
        },
      });
      return data.space;
    },
    options,
  );
}

/**
 * 스페이스 권한이 있는 멤버들 조회
 * @version V2
 * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_GetSpaceMembers}
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceMembers(
  spaceHashId: string,
  options?: SuspenseQueryOption<SpaceMembersResponse>,
) {
  const client = useZepApiClient();
  return useSuspendedQuery(
    [QUERY_KEY_SPACE_MEMBERS, spaceHashId],
    async () => {
      const { data } = await client.get(`/v2/spaces/members`, {
        params: {
          spaceHashId,
        },
      });
      return data;
    },
    options,
  );
}

/**
 * 스페이스 블랙리스트 조회
 * @version V2
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceBlackList(
  spaceHashId: string,
  options?: SuspenseQueryOption<SpaceBlackListResponse>,
) {
  const client = useZepApiClient();
  return useSuspendedQuery(
    [QUERY_KEY_SPACE_BLACKLIST, spaceHashId],
    async () => {
      const { data } = await client.get(`/v2/spaces/blacklist`, {
        params: {
          spaceHashId,
        },
      });
      return data;
    },
    options,
  );
}

/**
 * 스페이스 블랙리스트 개수가 max count인지 확인
 * @version V2
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceMaxBlackList(
  spaceHashId: string,
  options?: UseZepApiQueryOptions<{ status: number; hasMaxCount: boolean }>,
) {
  const client = useZepApiClient();
  return useQuery(
    [QUERY_KEY_SPACE_MAX_BLACKLIST, spaceHashId] as QueryKey,
    async () => {
      const { data } = await client.get(`/v2/spaces/max-blacklist`, {
        params: {
          spaceHashId,
        },
      });
      return data;
    },
    options,
  );
}

/**
 * 스페이스 카테고리 불러오는 api
 * @version V2
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceCategories(
  params: {
    locale: string;
  },
  options?: SuspenseQueryOption<{
    status: number;
    categories: SpaceCategory[];
  }>,
) {
  const client = useZepApiClient();

  return useSuspendedQuery(
    [QUERY_KEY_SPACE_CATEGORY, params],
    async () => {
      const { data } = await client.get<{
        status: number;
        categories: SpaceCategory[];
      }>('/v2/spaces/categories', {
        headers: {
          'accept-language': params.locale,
        },
      });

      // priority 가 0인 경우(기타)에는 맨 끝에 배치
      data.categories.sort((a, b) => {
        if (a.priority === 0) {
          return 1;
        } else if (b.priority === 0) {
          return -1;
        }
        return a.priority - b.priority;
      });
      return data;
    },
    { ...options },
  );
}

/**
 * 스페이스 맵 정보 조회 API Hook (SEO, 스페이스 초기 설정)
 * @version V2
 * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_GetMapAccessInfo}
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export async function fetchSpaceMapAccessInfo(
  client: AxiosInstance,
  params: SpaceMapAccessInfoRequest,
) {
  const { data } = await client.get<SpaceMapAccessInfoResponse>(
    '/v2/spaces/access-info',
    {
      params,
    },
  );
  return data;
}

/**
 * 스페이스 맵 정보 조회 API Hook (SEO, 스페이스 초기 설정) *
 * @returns Query States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceMapAccessInfo(
  params: SpaceMapAccessInfoRequest,
  options?: SuspenseQueryOption<SpaceMapAccessInfoResponse>,
) {
  const client = useZepApiClient();

  return useSuspendedQuery(
    [QUERY_KEY_SPACE_MAP_ACCESS_INFO, params],
    () => fetchSpaceMapAccessInfo(client, params),
    { ...options },
  );
}

/**
 * @desc 스페이스 설정 정보 불러오기
 * @see {@link https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_GetSpaceSettings} *
 * @returns Query States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useSpaceSettings(
  spaceHashId: string,
  options?: UseZepApiQueryOptions<SpaceSettingsResponse>,
) {
  const client = useZepApiClient();

  return useQuery(
    [QUERY_KEY_SPACE_SETTINGS, spaceHashId] as QueryKey,
    async () => {
      const { data } = await client.get<SpaceSettingsResponse>(
        '/v2/spaces/settings',
        {
          params: {
            spaceHashId,
          },
        },
      );
      return data;
    },
    options,
  );
}

/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useMySpaces(
  options?: UseZepApiQueryOptions<OwnedSpacesResponse>,
) {
  const client = useZepApiClient();
  return useQuery(
    [QUERY_KEY_SPACES, 'own-me'] as QueryKey,
    async () => {
      const { data } = await client.get<OwnedSpacesResponse>(
        '/v2/spaces/own-me',
      );
      return data;
    },
    options,
  );
}

/**
 * 내가 방문한 스페이스 목록 검색
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export const useSearchVisitedSpaces = (
  inputQuery: string,
  options?: SuspendedUseQueryOptions<SearchedSpacesResponse>,
) => {
  const client = useZepApiClient();
  return useSuspendedQuery(
    [QUERY_KEY_SPACES, 'visited', inputQuery],
    async () => {
      const { data } = await client.get('/v2/spaces/search/recently-visited', {
        params: { spaceName: inputQuery },
      });
      return data;
    },
    options,
  );
};

/**
 * 내가 소유한 스페이스 목록 검색
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export const useSearchRelatedSpaces = (
  inputQuery: string,
  options?: SuspendedUseQueryOptions<SearchedSpacesResponse>,
) => {
  const client = useZepApiClient();

  return useSuspendedQuery(
    [QUERY_KEY_SPACES, 'related', inputQuery],
    async () => {
      const { data } = await client.get('/v2/spaces/search/related-me', {
        params: { spaceName: inputQuery },
      });
      return data;
    },
    options,
  );
};

const QUERY_KEY_SPACE_METADATA_FOR_PLAN = 'spaceMetadataForPlan';

export function useSpaceMetadataForPlan<
  Response extends {
    status: number;
    isGrandfatheredPlan: boolean;
    isDowngradedPlan: boolean;
    hasUsedDashboard: boolean;
  },
>(params: { spaceHashId: string }, options?: UseZepApiQueryOptions<Response>) {
  const client = useZepApiClient();

  return useSuspendedQuery(
    [QUERY_KEY_SPACE_METADATA_FOR_PLAN, params.spaceHashId] as QueryKey,
    async () => {
      const { data } = await client.get<Response>(
        '/v2/spaces/premium-option-insight',
        {
          params: {
            spaceHashId: params.spaceHashId,
          },
        },
      );
      return data;
    },
    options,
  );
}

export function useSampleDashboardSpaceHashId() {
  const client = useZepApiClient();

  return useSuspendedQuery(['sample-dashboard-space-hash-id'], async () => {
    const { data } = await client.get<{ spaceHashId: string }>(
      '/v2/spaces/sample-dashboard-space',
    );
    return data.spaceHashId;
  });
}
