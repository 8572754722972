import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Space,
  SpaceMemberListType,
  SpaceRealTimeData,
  SpaceSettingsRequestModel,
  SpaceSettingsResponse,
  SpaceV1Response,
} from '@zep/types';

import { buildFormData } from '../../utils';
import { UseZepApiMutationOptions } from '../types';
import { useZepApiClient } from '../ZepApiProvider';

import { CreateSpaceV2Request } from './sidebar/types';
import {
  QUERY_KEY_SPACE_DETAIL,
  QUERY_KEY_SPACE_MEMBERS,
  QUERY_KEY_SPACES,
} from './query';
import { CreateSpaceData, CreateSpaceTypeV1 } from './types';

/**
 * * @desc 스페이스 생성 api v2
 * * @alias API_V2_SPACES
 * * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_CreateSpace}
 * * @return Mutation States
 * * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useCreateSpaceV2(
  options?: UseZepApiMutationOptions<
    Space,
    CreateSpaceV2Request | CreateSpaceData
  >,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    async space => {
      const { data } = await client.post(
        '/v2/spaces',
        {
          ...space,
        },
        {
          timeout: 1000 * 60 * 5, // 스페이스 생성이 서버단에서 늦은 요청이 올 수 있어 해당 api 5분으로 늘려놈.
        },
      );
      return data.space;
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACES]);
      },
    },
  );
}

/**
 * 스페이스 복사 Mutation Hook
 * @version V2
 * @returns Mutation States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useCopySpace(
  options?: UseZepApiMutationOptions<string, string>,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    async spaceHashId => {
      const { data } = await client.post('/v2/spaces/copy', { spaceHashId });
      return data.spaceHashId;
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACES]);
      },
    },
  );
}

/**
 * 스페이스 삭제 Mutation Hook
 * @version V2
 * @returns Mutation States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useDeleteSpace(
  options?: UseZepApiMutationOptions<void, string>,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    async spaceHashId => {
      await client.delete('/v2/spaces', { data: { spaceHashId } });
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACES]);
      },
    },
  );
}

/**
 * 스페이스 방문 기록 삭제 Mutation Hook
 * @version V2
 * @returns Mutation States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useDeleteSpaceVisitHistory(
  options?: UseZepApiMutationOptions<void, string>,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    async spaceHashId => {
      await client.delete('/v2/spaces/visit-histories', {
        data: { spaceHashId },
      });
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACES]);
      },
    },
  );
}

/**
 * 초대코드로 스페이스 찾기 Hook
 * @version V1
 * @returns Mutation States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useGetSpaceByInviteCode(
  options?: UseZepApiMutationOptions<SpaceV1Response, string>,
) {
  const client = useZepApiClient();
  return useMutation(
    async inviteCode => {
      const { data } = await client.get(`/spaces/byInviteID/${inviteCode}`);
      return data.space as SpaceV1Response;
    },
    {
      ...options,
    },
  );
}

/**
 * * @desc 스페이스 설정 정보 변경
 * * @alias API_V2_SPACES_SETTINGS
 * * @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_Settings}
 * * @return Mutation States
 * * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useUpdateSpaceSetting(
  options?: UseZepApiMutationOptions<
    SpaceSettingsResponse,
    SpaceSettingsRequestModel
  >,
) {
  const client = useZepApiClient();

  return useMutation(
    async (spaceFormData: SpaceSettingsRequestModel) => {
      // WARNING:
      // enableDomainRestriction=true && allowedDomains=''를 payload로 보내는 경우,
      // 서버는 enableDomainRestriction: false로 업데이트 후 맞춰서 응답을 줍니다. 헷갈릴 수 있는 스펙이라 적어둡니다.
      const { data } = await client.put('/v2/spaces/settings', spaceFormData);
      return data;
    },
    {
      ...options,
    },
  );
}

/**
 * * @desc 스페이스 설정 중 고급 설정 변경되어야하는 부분 tRoom 변경 패킷을 적용하기 위해 예외적으로 전용 api 사용
 * * @see {@link {https://dev-5.zep.us/swagger/index.html#/ApiV2Space/ApiV2Space_SetSpaceDonationSettings}
 * * @return Mutation States
 * * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useUpdateRealtimeSpaceSettings(
  options?: UseZepApiMutationOptions<void, SpaceRealTimeData>,
) {
  const client = useZepApiClient();

  return useMutation(
    async (sendData: SpaceRealTimeData) => {
      await client.put('/v2/spaces/settings/advanced', sendData);
    },
    { ...options },
  );
}

/**
 * 스페이스 이메일로 멤버를 초대하는 api
 *  @alias API_V2_SPACES_MEMBERS_INVITE
 *  @return Mutation States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useInviteSpaceMember(
  options?: UseZepApiMutationOptions<
    void,
    { spaceHashId: string; email: string }
  >,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ spaceHashId, email }: { spaceHashId: string; email: string }) => {
      await client.post(`/v2/spaces/members/invite`, {
        email,
        spaceHashId,
      });
    },
    {
      onSuccess(data, variables, context) {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACE_MEMBERS]);
      },
    },
  );
}

/**
 *  @desc 스페이스 멤버 역할을 바꾸는 api
 *  @alias API_V2_SPACES_MEMBERS_ROLE
 *  @return Mutation States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useUpdateSpaceMemberRole(
  options?: UseZepApiMutationOptions<
    { status: number },
    { spaceHashId: string; spacePlayerHashId: string; role: string }
  >,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      spaceHashId,
      spacePlayerHashId,
      role,
    }: {
      spaceHashId: string;
      spacePlayerHashId: string;
      role: string;
    }) => {
      return await client.put(`/v2/spaces/members/role`, {
        spaceHashId,
        spacePlayerHashId,
        role,
      });
    },
    {
      onSuccess(data, variables, context) {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACE_MEMBERS]);
      },
    },
  );
}

/**
 *  @desc 스페이스 멤버를 삭제하는 api
 *  @alias API_V2_SPACES_MEMBERS
 *  @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_DeleteSpaceMemeber}
 *  @return Mutation States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useDeleteSpaceMember(
  options?: UseZepApiMutationOptions<
    void,
    { spaceHashId: string; spacePlayerHashId: string }
  >,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      spaceHashId,
      spacePlayerHashId,
    }: {
      spaceHashId: string;
      spacePlayerHashId: string;
    }) => {
      await client.delete(`/v2/spaces/members`, {
        data: {
          spacePlayerHashId,
          spaceHashId,
        },
      });
    },
    {
      onSuccess(data, variables, context) {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries([QUERY_KEY_SPACE_MEMBERS]);
      },
    },
  );
}

/**
 *  @desc 스페이스 블랙리스트를 삭제하는 api
 *  @alias API_V2_SPACES_BLACKLIST
 *  @return Mutation States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useDeleteSpaceBlackList(
  options?: UseZepApiMutationOptions<
    void,
    { spaceHashId: string; spaceBlacklistHashId: string }
  >,
) {
  const client = useZepApiClient();
  return useMutation(
    async ({
      spaceHashId,
      spaceBlacklistHashId,
    }: {
      spaceHashId: string;
      spaceBlacklistHashId: string;
    }) => {
      await client.delete(`/v2/spaces/blacklist`, {
        data: {
          spaceHashId,
          spaceBlacklistHashId,
        },
      });
    },
    options,
  );
}

/**
 *  @desc 스페이스 공지 변경
 *  @alias API_V2_SPACES_NOTICE
 *  @version v1
 *  @see {@link {https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_EditSpacesNotice}
 *  @return Mutation States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export const useUpdateNotice = (
  options?: UseZepApiMutationOptions<
    void,
    { spaceHashId: string; notice: string }
  >,
) => {
  const queryClient = useQueryClient();
  const client = useZepApiClient();

  return useMutation(
    async ({
      spaceHashId,
      notice,
    }: {
      spaceHashId: string;
      notice: string;
    }) => {
      await client.put('/v2/spaces/notice', { notice, spaceHashId });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries([
          QUERY_KEY_SPACE_DETAIL,
          variables.spaceHashId,
        ]);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
};

/**
 *  @desc 맵 단위 임베드 모두 지우기
 *  @alias API_V2_MAPS_EMBED_OBJECTS
 *  @see {@link {https://localhost/swagger/index.html#/ApiV2Map/ApiV2Map_ClearEmbedObjects}
 *  @return Mutation States
 * */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useDeleteMapEmbedsObjects() {
  const client = useZepApiClient();

  return useMutation(async ({ mapHashId }: { mapHashId: string }) => {
    await client.delete(`/v2/maps/embed-objects`, {
      data: {
        mapHashId,
      },
    });
  });
}

/**
 * @desc 스페이스 멤버 리스트 csv 파일 업로드
 * @alias API_V2_SPACES_MEMBERS
 * @link https://localhost/swagger/index.html#/ApiV2Space/ApiV2Space_Guestlist
 * @returns Mutation States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useUploadSpaceMemberList(
  options?: UseZepApiMutationOptions<
    SpaceMemberListType,
    { spaceHashId: string; membersListFromFile: File }
  >,
) {
  const client = useZepApiClient();
  return useMutation(async ({ spaceHashId, membersListFromFile }) => {
    const { data } = await client.postForm(
      `/v2/spaces/members`,
      buildFormData({ spaceHashId, membersListFromFile }),
    );
    return data;
  }, options);
}

/**
 * @desc 스페이스 패스워드 검증 모달
 * @link https://dev-5.zep.us/swagger/index.html#/ApiV2Space/ApiV2Space_ConfirmSpacePassword
 * @returns Mutation States
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export function useConfirmSpacePassword(
  options?: UseZepApiMutationOptions<
    { status: number },
    { spaceHashId: string; password: string }
  >,
) {
  const client = useZepApiClient();
  return useMutation(async ({ spaceHashId, password }) => {
    return await client.post(`/v2/spaces/password-confirm`, {
      spaceHashId,
      password,
    });
  }, options);
}

/**
 * 입장 코드로 입장할 스페이스 정보 조회
 * @param options
 */
/**
 * @deprecated API type 마이그레이션이 필요합니다. README의 API Type Safety 가이드를 참고하세요: docs/api-type-safety.md
 */
export const useSpaceInformationByInviteCode = (
  options?: UseZepApiMutationOptions<
    {
      status: number;
      space: CreateSpaceTypeV1;
    },
    string
  >,
) => {
  const client = useZepApiClient();

  return useMutation(
    ['space-data-by-invite-code'],
    async inviteCode => {
      const { data } = await client.get(`/spaces/byInviteID/${inviteCode}`);
      return data;
    },
    options,
  );
};
